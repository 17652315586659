@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts"; 

* {
  margin: 0;
  padding: 0;
}

html, 
body,
#root {
  @apply h-full;
}

body {
  font-family: "MedievalSharp";
  @apply text-text;
  @apply bg-background;
}

.border-fancy {
  @apply bg-[url('../assets/border/top-left.svg'),_url('../assets/border/top.svg'),_url('../assets/border/top-right.svg'),_url('../assets/border/left.svg'),_url('../assets/border/right.svg'),_url('../assets/border/bottom-left.svg'),_url('../assets/border/bottom.svg'),_url('../assets/border/bottom-right.svg')];
  @apply bg-[position:left_top,_top,_right_top,_left,_right,_left_bottom,_bottom,_bottom_right];
  background-repeat: no-repeat, repeat-x, no-repeat, repeat-y, repeat-y, no-repeat, repeat-x, no-repeat;
  @apply bg-surface;
  @apply p-4;
}